import React from "react";
import bg from "../imgs/bg.jpg";

function Header() {
  return (
    <header>
      <img src={bg} />
    </header>
  );
}

export default Header;
